import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
  Tabs,
  Tab,
  Autocomplete,
  Button,
  Paper,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CustomTreeView from "../../Common/Components/CustomTreeView";
import { useSelector } from "react-redux";

function AddRecipe({ open, onClose, recipe, categories, isNew, province }) {
  const [active, setActive] = useState(false);
  const [visibilityActive, setVisibilityActive] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  const [indeterminateCheckbox, setIndeterminateCheckbox] = useState([]);
  const [selectedNodes, setSelectedNodes] = useState([]);
  const [noEmplacement, setNoEmplacement] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [videoFilesEn, setVideoFilesEn] = useState();
  const [videoFilesFr, setVideoFilesFr] = useState();
  const [TreeViewProvince, setTreeViewProvince] = useState();
  const [formData, setFormData] = useState({
    nameFr: "",
    nameEn: "",
    category: null,
    ingredientsFr: "",
    ingredientsEn: "",
    decorationsFr: "",
    decorationsEn: "",
    utensilsFr: "",
    utensilsEn: "",
    reducedPortionFr: "",
    reducedPortionEn: "",
    takeAwayFr: "",
    takeAwayEn: "",
    recipeFr: "",
    recipeEn: "",
    Province: [],
    images: [],
    uploadedVideoFr: "",
    uploadedVideoEn: "",
  });

  const userState = useSelector((state) => state.user);

  useEffect(() => {
    const fetchProvince = async () => {
      try {
        const provinceResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/Localization/GetProvince`,
          {
            method: "GET",
            withCredentials: true,
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userState.token}`,
            },
          }
        );

        const provinceData = await provinceResponse.json();
        TreeProvince(provinceData);
      } catch (e) {}
    };

    fetchProvince();
  }, []);

  // Populate fields when `recipe` changes
  useEffect(() => {
    if (isNew) {
      // Initialize empty form data for new recipes
      setFormData({
        nameFr: "",
        nameEn: "",
        category: null,
        ingredientsFr: "",
        ingredientsEn: "",
        decorationsFr: "",
        decorationsEn: "",
        utensilsFr: "",
        utensilsEn: "",
        reducedPortionFr: "",
        reducedPortionEn: "",
        takeAwayFr: "",
        takeAwayEn: "",
        recipeFr: "",
        recipeEn: "",
        province: [],
        images: [],
        UploadedVideoFr: "",
        UploadedVideoEn: "",
      });
      setVisibilityActive(false);
      setStartDate(null);
      setEndDate(null);
      setUploadedFiles([]);
      setSelectedCategory(null);
    } else if (recipe) {
      // Populate form data for editing an existing recipe
      setFormData({
        nameFr: recipe.nomFr || "",
        nameEn: recipe.nomEn || "",
        category: recipe.idRecetteCategorie
          ? categories.find((cat) => cat.id === recipe.idRecetteCategorie)
          : null,
        ingredientsFr: recipe.ingredientFr || "",
        ingredientsEn: recipe.ingredientEn || "",
        decorationsFr: recipe.decorationFr || "",
        decorationsEn: recipe.decorationEn || "",
        utensilsFr: recipe.ustensileFr || "",
        utensilsEn: recipe.ustensileEn || "",
        reducedPortionFr: recipe.portionMinFr || "",
        reducedPortionEn: recipe.portionMinEn || "",
        takeAwayFr: recipe.emporterFr || "",
        takeAwayEn: recipe.emporterEn || "",
        recipeFr: recipe.recetteFr || "",
        recipeEn: recipe.recetteEn || "",
      });
      setVisibilityActive(recipe.DateActivationDebut ? true : false);
      setStartDate(
        recipe.dateActivationDebut ? dayjs(recipe.dateActivationDebut) : null
      );
      setEndDate(
        recipe.dateActivationFin ? dayjs(recipe.dateActivationFin) : null
      );
      setUploadedFiles(
        recipe.image
          ? [
              {
                type: "existing",
                url: recipe.image,
                format: recipe.formatImage,
              },
            ]
          : []
      );
      setSelectedCategory(
        categories.find((cat) => cat.id === recipe.idRecetteCategorie)
      );
    }
    if (province) {
      setFormData((prevData) => ({
        ...prevData, // Preserve all existing fields in the state
        province: province, // Update the 'province' field
      }));
    }
  }, [recipe, isNew, categories]);

  useEffect(() => {
    // Populate the TreeViewProvince and match selected nodes with province IDs
    const fetchProvince = async () => {
      try {
        const provinceResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/Localization/GetProvince`,
          {
            method: "GET",
            withCredentials: true,
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userState.token}`,
            },
          }
        );

        const provinceData = await provinceResponse.json();
        const treeData = TreeProvince(provinceData);

        // Match the selected provinces by their IDs
        if (province && Array.isArray(province)) {
          const matchedIds = matchProvinceIds(treeData, province);
          setSelectedNodes(Number(matchedIds));
        }
      } catch (e) {
        console.error("Failed to fetch provinces:", e);
      }
    };

    fetchProvince();
  }, [province]); // Run when `province` or tree changes

  useEffect(() => {
    if (province) {
      console.log("Incoming Province Data:", province);

      // Ensure `TreeViewProvince` is already populated
      if (TreeViewProvince && Array.isArray(TreeViewProvince)) {
        // Match province IDs to TreeView nodes
        const matchedIds = matchProvinceIds(TreeViewProvince, province);

        // Set selected nodes
        setSelectedNodes(matchedIds);
      } else {
        console.warn("TreeViewProvince is not populated yet.");
      }
    }
  }, [province, TreeViewProvince]); // Run only when `province` or `TreeViewProvince` changes

  /**
   * Match selected province IDs to the tree structure
   * @param {Array} tree - Tree data structure
   * @param {Array} ids - Array of province IDs
   * @returns {Array} - Array of matching IDs
   */
  const matchProvinceIds = (tree, ids) => {
    const matchedIds = [];

    const traverseTree = (node) => {
      if (ids.includes(node.id)) {
        matchedIds.push(node.id);
      }
      if (node.children) {
        node.children.forEach(traverseTree);
      }
    };

    tree.forEach(traverseTree);
    return matchedIds;
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files || []);
    setUploadedFiles((prev) => [...prev, ...files]);
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files || []);
    setUploadedFiles((prev) => [...prev, ...files]);
  };

  const handleSave = async () => {
    const endpoint = isNew ? "AddRecipe" : "EditRecipe";
    const form = new FormData();

    // Append RecetteDTO properties
    form.append("Id", recipe?.id || 0); // For EditRecipe, Id must be sent

    form.append("IdRecetteCategorie", selectedCategory?.id || 0);

    form.append("NomFr", formData.nameFr);
    form.append("NomEn", formData.nameEn);
    form.append("IngredientFr", formData.ingredientsFr || null);
    form.append("IngredientEn", formData.ingredientsEn || null);
    form.append("DecorationFr", formData.decorationsFr || null);
    form.append("DecorationEn", formData.decorationsEn || null);
    form.append("UstensileFr", formData.utensilsFr || null);
    form.append("UstensileEn", formData.utensilsEn || null);
    form.append("RecetteFr", formData.recipeFr || null);
    form.append("RecetteEn", formData.recipeEn || null);
    form.append("PortionMinFr", formData.reducedPortionFr || null);
    form.append("PortionMinEn", formData.reducedPortionEn || null);
    form.append("EmporterFr", formData.takeAwayFr || null);
    form.append("EmporterEn", formData.takeAwayEn || null);
    form.append("DateActivationDebut", startDate?.toISOString() || "");
    form.append("DateActivationFin", endDate?.toISOString() || "");
    form.append("EstActif", !active || false);

    // Filter valid provinces and remove duplicates
    const validProvinces = Array.from(
      new Set(selectedNodes.filter((id) => id !== null && Number.isInteger(id)))
    );

    // Append each province ID individually
    if (validProvinces.length > 0) {
      validProvinces.forEach((id) => {
        form.append("Province", id);
      });
    }

    // Append Images if uploadedFiles is not null or undefined
    if (Array.isArray(uploadedFiles)) {
      console.log(uploadedFiles);
      uploadedFiles.forEach((file) => {
        form.append("UploadedImages", file);
      });
    }

    // Handle the French video file
    if (videoFilesFr) {
      // Convert the file to an array buffer (binary data)
      const arrayBuffer = await videoFilesFr.arrayBuffer();

      // Create a Blob and append it to the form under the correct key
      form.append(
        "UploadedVideoFr",
        new Blob([arrayBuffer]),
        videoFilesFr.name
      );
    }

    // Handle the English video file
    if (videoFilesEn) {
      form.append("UploadedVideoEn", videoFilesEn);
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/Recipe/${endpoint}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${userState.token}`,
          },
          credentials: "include",
          body: form, // FormData automatically sets the appropriate content type
        }
      );

      if (!response.ok) {
        const error = await response.json();
        console.error("Save failed:", error);
        return;
      }

      const result = await response.json();
      console.log("Saved Recipe:", result);
      onClose();
    } catch (error) {
      console.error("Error saving recipe:", error);
    }
  };

  const handleRemoveFile = (index) => {
    setUploadedFiles((prev) => prev.filter((_, i) => i !== index));
  };

  const TreeProvince = (data) => {
    if (!Array.isArray(data)) {
      console.error("Invalid province data:", data);
      return [];
    }

    const tree = [];
    let ids = 0;

    data.forEach((item) => {
      let country = tree.find((p) => p.paysFr === item.paysFr);
      if (!country) {
        country = {
          id: ids++,
          paysFr: item.paysFr,
          paysEn: item.paysEn,
          children: [],
        };
        tree.push(country);
      }

      let province = country.children.find(
        (p) => p.provinceFr === item.provinceFr
      );
      if (!province) {
        province = {
          id: item.noProvince,
          parent: country.id,
          provinceFr: item.provinceFr,
          provinceEn: item.provinceEn,
          children: [],
        };
        country.children.push(province);
      }
    });

    setTreeViewProvince(tree);
    return tree;
  };

  const handleExpandClick = (event) => {
    // prevent the click event from propagating to the checkbox
    event.stopPropagation();
  };

  const handleNodeSelect = (event, nodeId) => {
    event.stopPropagation();

    if (!Array.isArray(selectedNodes)) {
      setSelectedNodes([]); // Ensure it's an array
    }

    const allChild = getAllChild(nodeId);
    const fathers = getAllFathers(nodeId);

    if (selectedNodes.includes(nodeId)) {
      // Deselect node and its children/fathers
      setSelectedNodes((prev) =>
        prev.filter((id) => !allChild.concat(fathers).includes(id))
      );
    } else {
      // Select node and update parents if needed
      setSelectedNodes((prev) => [...prev, nodeId, ...allChild]);
    }
  };

  // Helper function to handle selecting nodes and updating parents
  const handleSelectNode = (nodeId, allChild, fathers) => {
    const ToBeChecked = [...allChild];
    const ToBeIndeterminate = [];

    fathers.forEach((fatherId) => {
      const fatherNode = bfsSearch(TreeViewProvince, fatherId);

      if (isAllChildrenChecked(fatherNode, ToBeChecked)) {
        ToBeChecked.push(fatherId);
      } else if (isAtLeastOneChildIsChecked(fatherNode, ToBeChecked)) {
        ToBeIndeterminate.push(fatherId);
      }
    });

    setSelectedNodes((prev) => prev.concat(ToBeChecked));
    setIndeterminateCheckbox((prev) => prev.concat(ToBeIndeterminate));

    updateNoEmplacementOnSelect(nodeId, allChild);
  };

  // Handle updating noEmplacement when deselecting
  const updateNoEmplacementOnDeselect = async (nodeId, allChild) => {
    const nodeData = bfsSearch(TreeViewProvince, nodeId);

    if (allChild.length > 1) {
      let leafNodes = [];
      getLeafNoEmplacement(leafNodes, nodeData);
      const diff = noEmplacement.filter((x) => !leafNodes.includes(x));
      await setNoEmplacement(diff);
    } else {
      await setNoEmplacement((prev) =>
        prev.filter((p) => p !== nodeData.noEmplacement)
      );
    }
  };

  // Handle updating noEmplacement when selecting
  const updateNoEmplacementOnSelect = async (nodeId, allChild) => {
    const nodeData = bfsSearch(TreeViewProvince, nodeId);

    if (allChild.length > 1) {
      let leafNodes = [];
      getLeafNoEmplacement(leafNodes, nodeData);

      const diff = leafNodes.filter((x) => !noEmplacement.includes(x));
      await setNoEmplacement((prev) => prev.concat(diff));
    } else {
      await setNoEmplacement((prev) => prev.concat(nodeData.noEmplacement));
    }
  };

  function getLeafNoEmplacement(leafNodes, obj) {
    if (obj.children) {
      obj.children.forEach(function (child) {
        getLeafNoEmplacement(leafNodes, child);
      });
    } else {
      leafNodes.push(obj.noEmplacement);
    }
  }

  const bfsSearch = (graph, targetId) => {
    const queue = [...graph];

    while (queue.length > 0) {
      const currNode = queue.shift();
      if (currNode.id === targetId) {
        return currNode;
      }
      if (currNode.children) {
        queue.push(...currNode.children);
      }
    }
    return []; // Target node not found
  };

  const getAllChild = (id) => {
    return getAllIds(bfsSearch(TreeViewProvince, id));
  };

  const getAllFathers = (id, list = []) => {
    const node = bfsSearch(TreeViewProvince, id);
    list.push(node.parent);
    if (node.parent) {
      return getAllFathers(node.parent, list);
    }
    return list;
  };

  function isAllChildrenChecked(node, test) {
    const leafChild = [];
    getLeafNodes(leafChild, node);

    const nodeIdIndex = leafChild.indexOf(node.id);
    leafChild.splice(nodeIdIndex, 1);

    for (let i = 0; i < leafChild.length; i++) {
      if (test.indexOf(leafChild[i].id) === -1) {
        return false;
      }
    }
    return true;
  }

  function isAtLeastOneChildIsChecked(node, list) {
    const allChild = getAllChild(node.id);
    for (let i = 0; i < allChild.length; i++) {
      if (list.includes(allChild[i])) {
        return true;
      }
    }
    return false;
  }

  function getLeafNodes(leafNodes, obj) {
    if (obj.children) {
      obj.children.forEach(function (child) {
        getLeafNodes(leafNodes, child);
      });
    } else {
      leafNodes.push(obj);
    }
  }

  function getAllIds(node, idList = []) {
    idList.push(node.id);
    if (node.children) {
      node.children.forEach((child) => getAllIds(child, idList));
    }
    return idList;
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle>{isNew ? "Add recipe" : "Recipes Editor"}</DialogTitle>
      <DialogContent>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box sx={{ p: 2 }}>
            {/* Checkboxes */}
            <Box sx={{ display: "flex", alignItems: "center", gap: 2, mb: 2 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={active}
                    onChange={(e) => setActive(e.target.checked)}
                  />
                }
                label="Est inactive"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={visibilityActive}
                    onChange={(e) => setVisibilityActive(e.target.checked)}
                  />
                }
                label="Activer la période de visibilité"
              />
            </Box>

            {/* Date Picker Section */}
            {visibilityActive && (
              <Box
                sx={{ display: "flex", alignItems: "center", gap: 2, mb: 2 }}
              >
                <Typography>Du</Typography>
                <DatePicker
                  label="Start Date"
                  value={startDate}
                  onChange={(date) => setStartDate(date)}
                  renderInput={(params) => (
                    <TextField {...params} size="small" />
                  )}
                />
                <Typography>au</Typography>
                <DatePicker
                  label="End Date"
                  value={endDate}
                  onChange={(date) => setEndDate(date)}
                  renderInput={(params) => (
                    <TextField {...params} size="small" />
                  )}
                />
              </Box>
            )}

            {/* Tabs */}
            <Tabs value={tabIndex} onChange={handleTabChange} sx={{ mb: 2 }}>
              <Tab label="Information" />
              <Tab label="Recette" />
              <Tab label="Portion réduite" />
              <Tab label="Emporter" />
              <Tab label="Ingrédient" />
              <Tab label="Décoration" />
              <Tab label="Vaisselle" />
              <Tab label="Location" />
            </Tabs>

            {/* Tab Content */}
            <Box>
              {tabIndex === 0 && (
                <>
                  <Box>
                    <TextField
                      name="nameFr"
                      label="Nom en français"
                      fullWidth
                      margin="normal"
                      value={formData.nameFr}
                      onChange={handleInputChange}
                    />
                    <TextField
                      name="nameEn"
                      label="Nom en anglais"
                      fullWidth
                      margin="normal"
                      value={formData.nameEn}
                      onChange={handleInputChange}
                    />
                    <Autocomplete
                      options={categories}
                      getOptionLabel={(option) =>
                        userState.lang == "EN" ? option.textEn : option.textFr
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Recipe Category"
                          fullWidth
                          margin="normal"
                        />
                      )}
                      value={selectedCategory}
                      onChange={(event, value) => setSelectedCategory(value)}
                    />
                  </Box>
                  {/* Drag-and-Drop Zone */}
                  <Box
                    sx={{
                      border: "2px dashed #ccc",
                      borderRadius: 4,
                      padding: 4,
                      textAlign: "center",
                      mt: 2,
                      cursor: "pointer",
                      backgroundColor: "#f9f9f9",
                      position: "relative",
                    }}
                    onDrop={handleDrop}
                    onDragOver={(e) => e.preventDefault()}
                  >
                    <Typography variant="body1" color="textSecondary">
                      Drag and drop photos here, or click to upload
                    </Typography>
                    <input
                      type="file"
                      accept="image/*"
                      multiple
                      onChange={handleFileChange}
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        opacity: 0,
                      }}
                    />
                  </Box>

                  {/* Video Upload Buttons */}
                  <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
                    <Button
                      variant="contained"
                      component="label"
                      color="primary"
                    >
                      Upload English Video
                      <input
                        type="file"
                        accept="video/*"
                        hidden
                        onChange={(e) => setVideoFilesEn(e.target.files[0])}
                      />
                    </Button>
                    <Button
                      variant="contained"
                      component="label"
                      color="secondary"
                    >
                      Upload French Video
                      <input
                        type="file"
                        accept="video/*"
                        hidden
                        onChange={(e) => setVideoFilesFr(e.target.files[0])}
                      />
                    </Button>
                  </Box>

                  {/* File Previews */}
                  <Box mt={2} display="flex" flexWrap="wrap" gap={2}>
                    {/* Uploaded Files Preview */}
                    {uploadedFiles.map((file, index) => {
                      const isExisting = file.type === "existing";
                      const fileURL = isExisting
                        ? file.url
                        : URL.createObjectURL(file);

                      // Determine file type
                      const fileType = file.type || file.name?.split(".").pop();

                      return (
                        <Box
                          key={index}
                          sx={{
                            border: "1px solid #ccc",
                            borderRadius: 4,
                            padding: 1,
                            textAlign: "center",
                            maxWidth: 150,
                          }}
                        >
                          {fileType && fileType.startsWith("video") ? (
                            <video
                              src={fileURL}
                              controls
                              style={{
                                maxWidth: "100%",
                                borderRadius: 4,
                              }}
                            >
                              Your browser does not support the video tag.
                            </video>
                          ) : (
                            <img
                              src={fileURL}
                              alt={`Preview ${index + 1}`}
                              style={{ maxWidth: "100%", borderRadius: 4 }}
                            />
                          )}
                          <Button
                            color="error"
                            size="small"
                            onClick={() => handleRemoveFile(index)}
                          >
                            Remove
                          </Button>
                        </Box>
                      );
                    })}

                    {/* French Video Preview */}
                    {videoFilesFr && (
                      <Box
                        sx={{
                          border: "1px solid #ccc",
                          borderRadius: 4,
                          padding: 1,
                          textAlign: "center",
                          maxWidth: 150,
                        }}
                      >
                        <video
                          src={URL.createObjectURL(videoFilesFr)}
                          controls
                          style={{
                            maxWidth: "100%",
                            borderRadius: 4,
                          }}
                        >
                          Your browser does not support the video tag.
                        </video>
                        <Button
                          color="error"
                          size="small"
                          onClick={() => setVideoFilesFr(null)}
                        >
                          Remove French Video
                        </Button>
                      </Box>
                    )}

                    {/* English Video Preview */}
                    {videoFilesEn && (
                      <Box
                        sx={{
                          border: "1px solid #ccc",
                          borderRadius: 4,
                          padding: 1,
                          textAlign: "center",
                          maxWidth: 150,
                        }}
                      >
                        <video
                          src={URL.createObjectURL(videoFilesEn)}
                          controls
                          style={{
                            maxWidth: "100%",
                            borderRadius: 4,
                          }}
                        >
                          Your browser does not support the video tag.
                        </video>
                        <Button
                          color="error"
                          size="small"
                          onClick={() => setVideoFilesEn(null)}
                        >
                          Remove English Video
                        </Button>
                      </Box>
                    )}
                  </Box>
                </>
              )}
              {tabIndex === 1 && (
                <Box>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    La recette en francais
                  </Typography>
                  <TextField
                    name="recipeFr"
                    multiline
                    rows={5}
                    fullWidth
                    value={formData.recipeFr}
                    onChange={handleInputChange}
                  />
                  <Typography variant="body2" sx={{ mt: 2, mb: 1 }}>
                    La recette en anglais
                  </Typography>
                  <TextField
                    name="recipeEn"
                    multiline
                    rows={5}
                    fullWidth
                    value={formData.recipeEn}
                    onChange={handleInputChange}
                  />
                </Box>
              )}
              {tabIndex === 2 && (
                <Box>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    La portion reduite en francais
                  </Typography>
                  <TextField
                    multiline
                    rows={5}
                    name="reducedPortionFr"
                    fullWidth
                    variant="outlined"
                    value={formData.reducedPortionFr}
                    onChange={handleInputChange}
                  />

                  <Typography variant="body2" sx={{ mt: 2, mb: 1 }}>
                    La portion reduite en anglais
                  </Typography>
                  <TextField
                    multiline
                    name="reducedPortionEn"
                    rows={5}
                    fullWidth
                    variant="outlined"
                    value={formData.reducedPortionEn}
                    onChange={handleInputChange}
                  />
                </Box>
              )}
              {tabIndex === 3 && (
                <Box>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    Emporter en francais
                  </Typography>
                  <TextField
                    multiline
                    name="takeAwayFr"
                    rows={5}
                    fullWidth
                    variant="outlined"
                    value={formData.takeAwayFr}
                    onChange={handleInputChange}
                  />

                  <Typography variant="body2" sx={{ mt: 2, mb: 1 }}>
                    Emporter en anglais
                  </Typography>
                  <TextField
                    multiline
                    name="takeAwayEn"
                    rows={5}
                    fullWidth
                    variant="outlined"
                    value={formData.takeAwayEn}
                    onChange={handleInputChange}
                  />
                </Box>
              )}
              {tabIndex === 4 && (
                <Box>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    La liste des ingrédients en français (un ingrédient par
                    ligne)
                  </Typography>
                  <TextField
                    multiline
                    rows={5}
                    name="ingredientsFr"
                    fullWidth
                    variant="outlined"
                    value={formData.ingredientsFr}
                    onChange={handleInputChange}
                  />

                  <Typography variant="body2" sx={{ mt: 2, mb: 1 }}>
                    La liste des ingrédients en anglais (un ingrédient par
                    ligne)
                  </Typography>
                  <TextField
                    multiline
                    rows={5}
                    name="ingredientsEn"
                    fullWidth
                    variant="outlined"
                    value={formData.ingredientsEn}
                    onChange={handleInputChange}
                  />
                </Box>
              )}
              {tabIndex === 5 && (
                <Box>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    La liste des decorations en français (une decoration par
                    ligne)
                  </Typography>
                  <TextField
                    multiline
                    rows={5}
                    name="decorationsFr"
                    fullWidth
                    variant="outlined"
                    value={formData.decorationsFr}
                    onChange={handleInputChange}
                  />

                  <Typography variant="body2" sx={{ mt: 2, mb: 1 }}>
                    La liste des decorations en anglais (une decoration par
                    ligne)
                  </Typography>
                  <TextField
                    multiline
                    rows={5}
                    fullWidth
                    name="decorationsEn"
                    variant="outlined"
                    value={formData.decorationsEn}
                    onChange={handleInputChange}
                  />
                </Box>
              )}
              {tabIndex === 6 && (
                <Box>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    La liste des vaiselles en français (un item par ligne)
                  </Typography>
                  <TextField
                    multiline
                    rows={5}
                    fullWidth
                    name="utensilsFr"
                    variant="outlined"
                    value={formData.utensilsFr}
                    onChange={handleInputChange}
                  />

                  <Typography variant="body2" sx={{ mt: 2, mb: 1 }}>
                    La liste des vaiselles en anglais (un item par ligne)
                  </Typography>
                  <TextField
                    multiline
                    rows={5}
                    name="utensilsEn"
                    fullWidth
                    variant="outlined"
                    value={formData.utensilsEn}
                    onChange={handleInputChange}
                  />
                </Box>
              )}
              {tabIndex === 7 && (
                <Box>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    Province
                  </Typography>
                  <Paper sx={{ m: 2 }}>
                    <CustomTreeView
                      TreeViewProvince={TreeViewProvince}
                      handleExpandClick={handleExpandClick}
                      handleNodeSelect={handleNodeSelect}
                      indeterminateCheckbox={indeterminateCheckbox}
                      selectedNodes={
                        Array.isArray(selectedNodes) ? selectedNodes : []
                      }
                      readOnly={false}
                    />
                  </Paper>
                </Box>
              )}
            </Box>
            <Box
              sx={{
                mt: 3,
                display: "flex",
                justifyContent: "flex-end",
                gap: 2,
              }}
            >
              <Button variant="contained" color="primary" onClick={handleSave}>
                Save
              </Button>
              <Button variant="outlined" color="secondary" onClick={onClose}>
                Cancel
              </Button>
            </Box>
          </Box>
        </LocalizationProvider>
      </DialogContent>
    </Dialog>
  );
}

export default AddRecipe;
