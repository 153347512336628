import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { FullWidthTabs } from "./TabEmplacementComponent";
import TabEmplacement from "./TabEmplacement";
import TabRestaurant from "./TabRestaurant";
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import TabTenant from "./TabTenant";
import TabWebTime from "./TabWebTime";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";

import Grid from "@mui/material/Grid";

import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import "../../css/Emplacement.css";
import { useSelector } from "react-redux";
import CustomAlert from "../Common/Components/CustomAlert";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: 800,
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function Emplacement() {
  const { t, i18n } = useTranslation(["Articles"]);
  const [restaurants, setRestaurants] = useState([]);
  const [restaurant, setRestaurant] = useState();
  const [currentRestaurant, setCurrentRestaurant] = useState();
  const [open, setOpen] = React.useState(false);

  const [ville, setVille] = useState("");
  const [region, setRegion] = useState("");
  const [adresse, setAdresse] = useState("");
  const [codePostal, setCodePostal] = useState("");

  const [regions, setRegions] = useState([]);
  const [country, setCountry] = useState("");
  const [countries, setCountries] = useState([]);
  const [province, setProvince] = useState("");
  const [provinces, setProvinces] = useState([]);

  const [locationName, setLocationName] = useState();
  const [alignment, setAlignment] = React.useState("web");
  const [showAlert, setShowAlert] = useState(false);

  const [typeMessage, setTypeMessage] = useState("");
  const [message, setMessage] = useState("");

  const userState = useSelector((state) => state.user);

  useEffect(() => {
    GetCountriesProvincesRegions();
  }, []);

  const GetCountriesProvincesRegions = async () => {
    fetch(`${process.env.REACT_APP_API_URL}/Localization/GetProvince`, {
      method: "GET",
      withCredentials: true,
      credentials: "include",

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userState.token}`,
      },
    })
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        let paysFr = [];
        let paysEn = [];
        let RegionFr = [];
        let RegionEn = [];
        let ProvinceFr = [];
        let ProvinceEn = [];

        data.forEach((element) => {
          paysFr.push(element.paysFr);
          paysEn.push(element.paysEn);
          ProvinceFr.push(element.provinceFr);
          ProvinceEn.push(element.provinceEn);
          RegionFr.push(element.regionFr);
          RegionEn.push(element.regionEn);
        });

        var uniquePaysFR = paysFr.filter((x, i) => paysFr.indexOf(x) === i);
        var uniquePaysEN = paysEn.filter((x, i) => paysEn.indexOf(x) === i);

        var uniqueProvinceEN = ProvinceEn.filter(
          (x, i) => ProvinceEn.indexOf(x) === i
        );
        var uniqueProvinceFR = ProvinceFr.filter(
          (x, i) => ProvinceFr.indexOf(x) === i
        );
        var uniqueRegionEN = RegionEn.filter(
          (x, i) => RegionEn.indexOf(x) === i
        );
        var uniqueRegionFR = RegionFr.filter(
          (x, i) => RegionFr.indexOf(x) === i
        );

        setProvinces(uniqueProvinceFR);
        setCountries(uniquePaysFR);
        setRegions(uniqueRegionFR);
      });
  };

  useEffect(() => {
    GetEmplacement();
  }, []);

  const handleOpen = async () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const GetEmplacement = async () => {
    fetch(`${process.env.REACT_APP_API_URL}/Location/GetEmplacement`, {
      method: "GET",
      withCredentials: true,
      credentials: "include",

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userState.token}`,
      },
    })
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        setRestaurants(data);
      });
  };

  const GetEmplacementsWithoutFilter = async () => {
    fetch(
      `${process.env.REACT_APP_API_URL}/Location/GetEmplacementsWithoutFilter`,
      {
        method: "GET",
        withCredentials: true,
        credentials: "include",

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userState.token}`,
        },
      }
    )
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        setRestaurants(data);
      });
  };

  const handleChangeEmplacement = (event, newValue) => {
    if (newValue) {
      const selectedRestaurant = restaurants.find((r) => r.nom === newValue);
      setCurrentRestaurant(selectedRestaurant);
      setRestaurant(newValue);
    } else {
      setCurrentRestaurant(null);
      setRestaurant(null);
    }
  };

  const handleCreateLocation = async () => {
    var dataNewLocation = {
      LocationName: locationName,
      city: ville,
      adress: adresse,
      zipCode: codePostal,
      region: region,
      province: province,
      country: country,
      username: userState.username,
    };

    fetch(`${process.env.REACT_APP_API_URL}/Location/CreateLocation`, {
      method: "POST",
      withCredentials: true,
      credentials: "include",

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userState.token}`,
      },
      body: JSON.stringify(dataNewLocation),
    })
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        if (data == "succes") {
          setTypeMessage("succes");
          setMessage("location saved");
          setShowAlert(true);
        }
        if (data == "echec") {
          setTypeMessage("echec");
          setMessage("There is an error, location not saved");
          setShowAlert(true);
        }
      });

    setCurrentRestaurant();
  };

  const handleChangeToggleButton = (event, newAlignment) => {
    setAlignment(newAlignment);
    if (newAlignment) {
      GetEmplacementsWithoutFilter();
    } else {
      GetEmplacement();
    }
  };

  const saveLocationData = async (data, userToken) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/Location/SaveLocation`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify(data),
        }
      );

      const result = await response.json();
      return {
        success: result === "succes",
        message:
          result === "succes"
            ? "Location saved successfully"
            : "There was an error saving the location",
      };
    } catch (error) {
      return {
        success: false,
        message: "Error connecting to server",
      };
    }
  };

  return (
    <>
      <Button onClick={handleOpen}>{t("NewLocation")}</Button>
      <ToggleButtonGroup
        color="primary"
        value={alignment}
        exclusive
        onChange={handleChangeToggleButton}
        aria-label="Platform"
      >
        <ToggleButton value={true}>{t("ShowInactiveLocation")}</ToggleButton>
      </ToggleButtonGroup>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="Emplacement__Modal">
          <h5>{t("CreateLocationTitle")}</h5>
          <Grid container spacing={4} className="Emplacement__Modal__grid">
            <Grid item xs={12} sm={4}>
              <TextField
                id="Nom"
                name="Nom"
                label={t("LocationName")}
                fullWidth
                size="small"
                autoComplete="off"
                variant="outlined"
                value={locationName}
                onChange={(e) => setLocationName(e.currentTarget.value)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                id="adresse"
                name="adresse"
                label={t("Adress")}
                fullWidth
                size="small"
                autoComplete="off"
                variant="outlined"
                value={adresse}
                onChange={(e) => setAdresse(e.currentTarget.value)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                id="city"
                name="city"
                label={t("City")}
                fullWidth
                size="small"
                autoComplete="off"
                variant="outlined"
                value={ville}
                onChange={(e) => setVille(e.currentTarget.value)}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                id="zipcode"
                name="zipcode"
                label={t("Zipcode")}
                fullWidth
                size="small"
                autoComplete="off"
                variant="outlined"
                value={codePostal}
                onChange={(e) => setCodePostal(e.currentTarget.value)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <InputLabel id="CountryLabel">{t("Country")}</InputLabel>
                <Select
                  id="Country"
                  name="Country"
                  label={t("Country")}
                  fullWidth
                  size="small"
                  autoComplete="off"
                  variant="outlined"
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                >
                  {countries.map((c, i) => (
                    <MenuItem key={i} value={c}>
                      {c}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <InputLabel id="ProvinceLabel">{t("Province")}</InputLabel>
                <Select
                  id="Province"
                  name="Province"
                  label={t("Province")}
                  fullWidth
                  size="small"
                  autoComplete="off"
                  variant="outlined"
                  value={province}
                  onChange={(e) => setProvince(e.target.value)}
                >
                  {provinces.map((p, i) => (
                    <MenuItem key={i} value={p}>
                      {p}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <InputLabel id="RegionLabel">{t("Region")}</InputLabel>
                <Select
                  id="Region"
                  name="Region"
                  label="Region"
                  fullWidth
                  size="small"
                  autoComplete="off"
                  variant="outlined"
                  value={region}
                  onChange={(e) => setRegion(e.target.value)}
                >
                  {regions.map((r, i) => (
                    <MenuItem key={i} value={r}>
                      {r}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Button onClick={(e) => handleCreateLocation(e)}>
            {t("Create")}
          </Button>
        </Box>
      </Modal>
      <Box sx={{ minWidth: 120 }}>
        <div className="APIKEY__filter">
          <FormControl fullWidth>
            <Autocomplete
              id={t("Emplacement")}
              options={restaurants.map((resto) => resto.nom)}
              onChange={handleChangeEmplacement}
              value={restaurant || null}
              renderInput={(params) => (
                <TextField {...params} label={t("Emplacement")} />
              )}
            />
          </FormControl>
        </div>
      </Box>
      <div style={{ height: 600, width: "100%" }}>
        <FullWidthTabs
          emplacement={currentRestaurant}
          TabEmplacement={<TabEmplacement emplacement={currentRestaurant} />}
          TabRestaurant={<TabRestaurant emplacement={currentRestaurant} />}
          TabTenant={<TabTenant emplacement={currentRestaurant} />}
          TabWebTime={<TabWebTime emplacement={currentRestaurant} />}
        />
      </div>

      <CustomAlert
        open={showAlert}
        closeMessage={setShowAlert}
        typeMessage={typeMessage}
        message={message}
      />
    </>
  );
}

export default Emplacement;
