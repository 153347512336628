import React, { useEffect, useState } from "react";
import { Grid, Paper, Box, TextField, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import CustomAlert from "../Common/Components/CustomAlert";

function TabTenant({ emplacement, onSave }) {
  const { t } = useTranslation(["Location"]);
  const userState = useSelector((state) => state.user);

  // Form state
  const [formData, setFormData] = useState({
    lessorName: "",
    address: "",
    phoneNumber: "",
    fax: "",
    email: "",
    contact: "",
    dateBeginning: null,
    dateEnding: null,
    optionRenew: "",
    noticePeriod: "",
  });

  // Alert state
  const [alert, setAlert] = useState({
    show: false,
    type: "",
    message: "",
  });

  useEffect(() => {
    if (emplacement) {
      setFormData({
        lessorName: emplacement.nomBailleur || "",
        address: emplacement.adresseBailleur || "",
        phoneNumber: emplacement.telAffaireBailleur || "",
        fax: emplacement.faxBailleur || "",
        email: emplacement.mailAffaireBailleur || "",
        contact: emplacement.contactBailleur || "",
        dateBeginning: emplacement.dateBailEffectif
          ? dayjs(emplacement.dateBailEffectif)
          : null,
        dateEnding: emplacement.dateBailExpiration
          ? dayjs(emplacement.dateBailExpiration)
          : null,
        optionRenew: emplacement.optionRenouvelerBailleur || "",
        noticePeriod: emplacement.periodeAvisBailleur || "",
      });
    }
  }, [emplacement]);

  const handleInputChange = (field) => (event) => {
    setFormData((prev) => ({
      ...prev,
      [field]: event.target.value,
    }));
  };

  const handleDateChange = (field) => (newValue) => {
    setFormData((prev) => ({
      ...prev,
      [field]: newValue,
    }));
  };

  const handleSave = async () => {
    const saveData = {
      noEmplacement: emplacement?.noEmplacement,
      ...formData,
      username: userState.username,
      tab: "Lessor",
    };

    const result = await onSave(saveData, userState.token);

    setAlert({
      show: true,
      type: result.success ? "success" : "error",
      message: result.message,
    });
  };

  return (
    <Paper elevation={3} sx={{ mx: "5%" }}>
      <Box sx={{ p: 5 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label={t("LessorName")}
              value={formData.lessorName}
              onChange={handleInputChange("lessorName")}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label={t("Address")}
              value={formData.address}
              onChange={handleInputChange("address")}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label={t("BusinessPhone")}
              value={formData.phoneNumber}
              onChange={handleInputChange("phoneNumber")}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label={t("Fax")}
              value={formData.fax}
              onChange={handleInputChange("fax")}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label={t("BusinessEmail")}
              value={formData.email}
              onChange={handleInputChange("email")}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label={t("Contact")}
              value={formData.contact}
              onChange={handleInputChange("contact")}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label={t("BeginLeaseDate")}
                value={formData.dateBeginning}
                onChange={handleDateChange("dateBeginning")}
                fullWidth
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={12} sm={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label={t("EndLeaseDate")}
                value={formData.dateEnding}
                onChange={handleDateChange("dateEnding")}
                fullWidth
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label={t("OptionRenew")}
              value={formData.optionRenew}
              onChange={handleInputChange("optionRenew")}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label={t("NoticePeriod")}
              value={formData.noticePeriod}
              onChange={handleInputChange("noticePeriod")}
            />
          </Grid>

          <Grid item xs={12}>
            <Button variant="contained" onClick={handleSave} sx={{ mt: 2 }}>
              {t("Save")}
            </Button>
          </Grid>
        </Grid>
      </Box>

      <CustomAlert
        open={alert.show}
        closeMessage={() => setAlert((prev) => ({ ...prev, show: false }))}
        typeMessage={alert.type}
        message={alert.message}
      />
    </Paper>
  );
}

export default TabTenant;
