import React, { useEffect, useState } from "react";
import CustomBreadcrumbs from "./CustomBreadcrumbs";
import { useTranslation } from "react-i18next";
import RecipesCategory from "./RecipesCategory";
import FABMenu from "../../components/Common/Components/FABMenu";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import SettingsIcon from "@mui/icons-material/Settings";

import "./css/RecipesView.css";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Autocomplete,
  TextField,
} from "@mui/material";

import RecipesList from "./RecipesList";
import Recipe from "./Recipe";
import { useSelector } from "react-redux";
import { Typography, CircularProgress } from "@mui/material";
import AddRecipe from "./Admin/AddRecipe";
import AddRecipeCategory from "./Admin/AddRecipeCategory";
import EditOrderRecipe from "./Admin/EditOrderRecipe";
import CustomAlert from "../Common/Components/CustomAlert";

function RecipesView() {
  const { t, i18n } = useTranslation(["Recipes"]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedRecipe, setSelectedrecipe] = useState(null);
  const [recipe, setRecipe] = useState(null);
  const [categories, setCategories] = useState([]);
  const [imagesRecipe, setImagesRecipe] = useState();
  const [recipeLoading, setRecipeLoading] = useState(true); // Loading state
  const [loading, setLoading] = useState(false); // Loading state
  const [isNew, setIsNew] = useState(false);
  const [openAddRecipe, setOpenAddRecipe] = useState();
  const [openEditCategory, setOpenEditCategory] = useState();
  const [openEditOrder, setOpenEditOrder] = useState();

  const [allRecipes, setAllRecipes] = useState([]); // State for all recipes

  const [allRecipeswithInactive, setAllRecipesWithInactive] = useState([]);
  const [showInactive, setShowInactive] = useState(false);
  const userState = useSelector((state) => state.user);

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const [alertOpen, setAlertOpen] = useState(false); // State for alert visibility
  const [alertSeverity, setAlertSeverity] = useState("error"); // State for alert severity

  const hasAdminAccess = (roles) =>
    ["RecipeAdmin", "AdminTI"].some((role) => roles.includes(role));

  const fetchCategories = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/Recipe/GetRecipeCategories`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${userState.token}`,
          },
          credentials: "include",
        }
      );
      if (!response.ok) throw new Error("Failed to fetch categories");
      const data = await response.json();

      // Only add inactive category if showInactive is true
      if (showInactive) {
        const inactiveCategory = {
          id: "inactive",
          textFr: "Recettes Inactives",
          textEn: "Inactive Recipes",
          image: null,
        };
        setCategories([...data, inactiveCategory]);
      } else {
        setCategories(data);
      }
    } catch (error) {
      console.error("Error fetching Recipes:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchAllRecipes = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/Recipe/GetAllRecipes`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${userState.token}`,
          },
          credentials: "include",
        }
      );
      if (!response.ok) throw new Error("Failed to fetch recipes");
      const data = await response.json();

      // Set all recipes (including inactive ones)
      setAllRecipesWithInactive(data.map((recipe) => recipe.recette));

      // Filter active recipes
      const activeRecipes = data.filter((recipe) => recipe.recette.estActif);
      setAllRecipes(activeRecipes.map((recipe) => recipe.recette));
    } catch (error) {
      console.error("Error fetching all recipes:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchRecipe = async () => {
    if (!selectedRecipe) return; // Guard clause
    setRecipeLoading(true);
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/Recipe/GetRecipeById/${selectedRecipe.id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userState.token}`,
          },
          credentials: "include",
        }
      );
      if (!response.ok) throw new Error("Failed to fetch recipe");
      const data = await response.json();
      setRecipe(data);
      setRecipeLoading(false);
    } catch (error) {
      console.error("Error fetching Recipe:", error);
    } finally {
      setLoading(false); // Set loading to false after fetch
    }
  };

  // Function to handle category selection
  const handleSelectCategory = (e) => {
    const selectedCategoryName = e.currentTarget.getAttribute("data");
    const selectedCategory = categories.find((category) =>
      userState.lang === "FR"
        ? category.textFr === selectedCategoryName
        : category.textEn === selectedCategoryName
    );
    setSelectedCategory(selectedCategory); // Set the selected category object
    setSelectedrecipe(null);
    setRecipe(null);
  };

  // Handle showing/hiding inactive recipes
  const handleShowInactive = () => {
    setShowInactive((prev) => !prev);
    setSelectedCategory(null);
    setSelectedrecipe(null);
    setRecipe(null);
  };

  useEffect(() => {
    fetchCategories();

    fetchAllRecipes();
  }, [showInactive]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional for smooth scrolling
    });
    fetchRecipe();
  }, [selectedRecipe, selectedCategory]);

  const handleDeleteCategory = async (categoryId) => {
    try {
      // Fetch recipes for the given category to verify if it contains any
      console.log(categoryId);
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/Recipe/GetRecipesByIdByCategory/${categoryId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userState.token}`,
          },
          credentials: "include",
        }
      );

      if (!response.ok)
        throw new Error("Failed to fetch recipes for the category");

      const recipes = await response.json();

      if (recipes.length > 0) {
        // Set error message if recipes exist
        setErrorMessage(
          "Delete is impossible because there are recipes in this category."
        );
        setAlertSeverity("error");
        setAlertOpen(true);
        return;
      }

      // Open the confirmation dialog
      setCategoryToDelete(categoryId);
      setOpenDeleteDialog(true);
    } catch (error) {
      console.error("Error checking recipes for the category:", error);
      setErrorMessage("An error occurred while checking the category.");
      setAlertSeverity("error");
      setAlertOpen(true);
    }
  };

  // Function to confirm deletion
  const confirmDeleteCategory = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/Recipe/DeleteCategory/${categoryToDelete}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userState.token}`,
          },
          credentials: "include",
        }
      );

      if (!response.ok) throw new Error("Failed to delete category");

      // Update categories in the state
      setCategories(
        categories.filter((category) => category.id !== categoryToDelete)
      );

      setErrorMessage("Category deleted successfully.");
      setAlertSeverity("success");
      setAlertOpen(true);

      // Close the dialog and clear state
      setOpenDeleteDialog(false);
      setCategoryToDelete(null);
    } catch (error) {
      console.error("Error deleting category:", error);
      setErrorMessage("An error occurred while deleting the category.");
      setAlertSeverity("error");
      setAlertOpen(true);
    }
  };

  // Function to clear error message
  const clearErrorMessage = () => {
    setAlertOpen(false);
    setErrorMessage("");
  };

  // Function to close the dialog
  const handleCloseDialog = () => {
    setOpenDeleteDialog(false);
    setCategoryToDelete(null);
  };

  console.log(allRecipes);

  return (
    <div className="RecipesView_Container">
      <div
        className="RecipesHeader"
        style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
      >
        {/* Top Row: FABMenu, Recipes Title, and Search Bar */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "1rem",
            flexWrap: "wrap",
          }}
        >
          {hasAdminAccess(userState.roles) && (
            <FABMenu
              className="AddRecipes"
              fabIcon={<AddIcon />}
              fabColor="primary"
              fabStyle={{ backgroundColor: "#009ddf", color: "white" }}
              menuItems={[
                {
                  label: "Add Recipe",
                  action: () => {
                    setOpenAddRecipe(true);
                    setIsNew(true);
                  },
                },
                {
                  label: "Add Category",
                  action: () => {
                    setOpenEditCategory(true);
                    setIsNew(true);
                  },
                },
              ]}
            />
          )}
          <h3 style={{ margin: 0, flexShrink: 0 }}>{t("Recipes")}</h3>
          <Autocomplete
            options={showInactive ? allRecipeswithInactive : allRecipes}
            getOptionLabel={(option) =>
              userState.lang === "FR" ? option.nomFr || "" : option.nomEn || ""
            }
            onChange={(event, value) => setSelectedrecipe(value)}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("SearchRecipes")}
                variant="outlined"
              />
            )}
            sx={{ flexGrow: 1, minWidth: "200px" }}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
          />
        </div>

        {/* Breadcrumbs Row */}
        <CustomBreadcrumbs
          category={selectedCategory}
          recipe={selectedRecipe}
          setRecipe={setRecipe}
          setSelectedRecipe={setSelectedrecipe}
          setCategory={setSelectedCategory}
        />
      </div>

      {/* Conditionally render RecipesList if a category is selected */}

      {recipe && (
        <div className="RecipeView__Recipe">
          {recipeLoading && (
            <div className={"RecipeLoading"}>
              <CircularProgress
                className="RecipeViewLoadingSpinner"
                size="5rem"
              />
            </div>
          )}
          {hasAdminAccess(userState.roles) && (
            <FABMenu
              fabIcon={<EditIcon />}
              fabColor="primary"
              aria-label="edit-recipe"
              className="EditRecipe"
              fabStyle={{ backgroundColor: "#40c1e0", color: "white" }}
              onClick={() => {
                setOpenAddRecipe(true);
                setIsNew(false);
              }}
            />
          )}

          <Recipe
            key={recipe.recette.id}
            recipe={recipe && recipe.recette}
            images={recipe && recipe.images}
            videos={
              recipe && userState.lang === "FR"
                ? recipe.videoFr
                : recipe.videoEn
            }
          />
        </div>
      )}
      {selectedCategory && (
        <div className="RecipeView__Recipes">
          <div className="TriangleDecoration" />

          <div className="RecipeView__ImageCategory">
            <img
              src={`data:image/png;base64,${selectedCategory.image}`}
              alt={selectedCategory.name}
            />
            <Typography variant="h6">
              {userState.lang === "FR"
                ? selectedCategory.textFr
                : selectedCategory.textEn}
            </Typography>
          </div>
          <RecipesList
            category={selectedCategory}
            setRecipe={setSelectedrecipe}
            selectedRecipe={selectedRecipe}
            setImagesRecipe={setImagesRecipe}
            inactiveRecipe={allRecipes}
            editRecipe={(recipe) => {
              console.log(recipe);
              setSelectedrecipe(recipe.recette);
              setOpenAddRecipe(true);
              setIsNew(false);
            }}
            isAdmin={hasAdminAccess(userState.roles)}
          />
        </div>
      )}
      <div className="RecipesView__GridCategory">
        <FABMenu
          className="Settings"
          fabIcon={<SettingsIcon />}
          fabColor="primary"
          fabStyle={{ backgroundColor: "#009ddf", color: "white" }}
          menuItems={[
            {
              label: "Manage Order",
              action: () => {
                setOpenEditOrder(true);
              },
            },
            {
              label: showInactive ? "Hide Inactive" : "Show Inactive",
              action: handleShowInactive,
            },
          ]}
        />
        <div className="TriangleDecoration" />

        {categories.map((category) => (
          <RecipesCategory
            name={userState.lang === "FR" ? category.textFr : category.textEn}
            image={category.image}
            selectedCategory={selectedCategory}
            onClick={handleSelectCategory}
            onDelete={() => handleDeleteCategory(category.id)}
            onEdit={(name) => {
              setSelectedCategory(
                categories.find(
                  (cat) => cat.textFr === name || cat.textEn === name
                )
              );
              setOpenEditCategory(true);
              setIsNew(false);
            }}
            isAdmin={hasAdminAccess(userState.roles)}
          />
        ))}
        <AddRecipe
          open={openAddRecipe}
          onClose={() => setOpenAddRecipe(false)}
          recipe={recipe ? recipe.recette : selectedRecipe}
          province={recipe ? recipe.province : null}
          categories={categories}
          isNew={isNew}
        />
        <AddRecipeCategory
          open={openEditCategory}
          onClose={() => setOpenEditCategory(false)}
          category={selectedCategory}
          isNew={isNew}
        />
        <EditOrderRecipe
          open={openEditOrder}
          onClose={() => setOpenEditOrder(false)}
          categories={categories}
        />
        <Dialog
          open={openDeleteDialog}
          onClose={handleCloseDialog}
          aria-labelledby="delete-category-dialog-title"
          aria-describedby="delete-category-dialog-description"
        >
          <DialogTitle id="delete-category-dialog-title">
            Confirm Delete
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="delete-category-dialog-description">
              Are you sure you want to delete this category? This action cannot
              be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={confirmDeleteCategory} color="error" autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        <CustomAlert
          open={alertOpen}
          message={errorMessage}
          typeMessage={alertSeverity}
          closeMessage={clearErrorMessage}
        />
      </div>
      <div className="RecipesView__Footer" />
    </div>
  );
}

export default RecipesView;
