import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import CustomAlert from "../Common/Components/CustomAlert";

function TabRestaurant({ emplacement, onSave }) {
  const { t } = useTranslation(["Location"]);
  const userState = useSelector((state) => state.user);

  // Form state
  const [formData, setFormData] = useState({
    siegeNumber: "",
    surface: "",
    ip: "",
    deliveryCost: "",
    deliveryCostByPallet: "",
    typePaiement: "",
  });

  // Alert state
  const [alert, setAlert] = useState({
    show: false,
    type: "",
    message: "",
  });

  // Payment types state
  const [typePaiements, setTypePaiements] = useState([]);

  useEffect(() => {
    getTypePaiement();
  }, []);

  useEffect(() => {
    if (emplacement) {
      setFormData({
        siegeNumber: emplacement.nbSiege || "",
        surface: emplacement.superficie || "",
        ip: emplacement.ipAddress || "",
        deliveryCost: emplacement.prixLivraison || "",
        deliveryCostByPallet: emplacement.prixLivraisonParPalette || "",
        typePaiement: emplacement.nomTypePaiementFr || "",
      });
    }
  }, [emplacement]);

  const handleInputChange = (field) => (event) => {
    setFormData((prev) => ({
      ...prev,
      [field]: event.target.value,
    }));
  };

  const getTypePaiement = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/emplacement/GetTypePaiement`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userState.token}`,
          },
        }
      );
      const data = await response.json();
      setTypePaiements(data);
    } catch (error) {
      setAlert({
        show: true,
        type: "error",
        message: "Error loading payment types",
      });
    }
  };

  const handleSave = async () => {
    const saveData = {
      noEmplacement: emplacement?.noEmplacement,
      ...formData,
      username: userState.username,
      tab: "Restaurant",
    };

    const result = await onSave(saveData, userState.token);

    setAlert({
      show: true,
      type: result.success ? "success" : "error",
      message: result.message,
    });
  };

  return (
    <Paper elevation={3} sx={{ mx: "5%" }}>
      <Box sx={{ p: 5 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={t("openDate")}
                  value={emplacement ? dayjs(emplacement.dateOuverture) : null}
                  readOnly
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={t("closureDate")}
                  value={emplacement ? dayjs(emplacement.dateFermeture) : null}
                  readOnly
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label={t("seatsNumber")}
              value={formData.siegeNumber}
              onChange={handleInputChange("siegeNumber")}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label={t("Surface")}
              value={formData.surface}
              onChange={handleInputChange("surface")}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>{t("PaymentType")}</InputLabel>
              <Select
                value={formData.typePaiement}
                onChange={handleInputChange("typePaiement")}
                label={t("PaymentType")}
              >
                {typePaiements.map((t, i) => (
                  <MenuItem key={i} value={t.nomFr}>
                    {t.nomFr}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label={t("IPCaisse")}
              value={formData.ip}
              onChange={handleInputChange("ip")}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label={t("DeliveryCost")}
              value={formData.deliveryCost}
              onChange={handleInputChange("deliveryCost")}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label={t("DeliveryCostByPallets")}
              value={formData.deliveryCostByPallet}
              onChange={handleInputChange("deliveryCostByPallet")}
            />
          </Grid>

          <Grid item xs={12}>
            <Button variant="contained" onClick={handleSave} sx={{ mt: 2 }}>
              {t("Save")}
            </Button>
          </Grid>
        </Grid>
      </Box>

      <CustomAlert
        open={alert.show}
        closeMessage={() => setAlert((prev) => ({ ...prev, show: false }))}
        typeMessage={alert.type}
        message={alert.message}
      />
    </Paper>
  );
}

export default TabRestaurant;
