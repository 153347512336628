import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import TabEmplacement from "./TabEmplacement";
import TabRestaurant from "./TabRestaurant";
import TabTenant from "./TabTenant";
import TabWebTime from "./TabWebTime";

const TabPanel = ({ children, value, index, ...props }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`location-tabpanel-${index}`}
    aria-labelledby={`location-tab-${index}`}
    {...props}
  >
    {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
  </div>
);

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index) => ({
  id: `location-tab-${index}`,
  "aria-controls": `location-tabpanel-${index}`,
});

export const FullWidthTabs = ({ emplacement, onSave }) => {
  const [value, setValue] = React.useState(0);
  const { t } = useTranslation(["Location"]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ bgcolor: "background.paper", width: "100%" }}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="location tabs"
        >
          <Tab label={t("TabNameGeneral")} {...a11yProps(0)} />
          <Tab label={t("TabNameDetails")} {...a11yProps(1)} />
          <Tab label={t("TabNameTenant")} {...a11yProps(2)} />
          <Tab label={t("TabNameSiteWebinfo")} {...a11yProps(3)} />
        </Tabs>
      </AppBar>

      <TabPanel value={value} index={0}>
        <TabEmplacement emplacement={emplacement} onSave={onSave} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <TabRestaurant emplacement={emplacement} onSave={onSave} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <TabTenant emplacement={emplacement} onSave={onSave} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <TabWebTime emplacement={emplacement} onSave={onSave} />
      </TabPanel>
    </Box>
  );
};

export default FullWidthTabs;
