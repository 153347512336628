import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimeField } from "@mui/x-date-pickers/TimeField";
import dayjs from "dayjs";
import CustomAlert from "../Common/Components/CustomAlert";

function TabWebTime({ emplacement, onSave }) {
  const { t } = useTranslation(["Articles"]);
  const userState = useSelector((state) => state.user);

  // Form state
  const [formData, setFormData] = useState({
    // Services
    dinein: false,
    takeout: false,
    delivery: false,
    meetingRoom: false,
    terrace: false,

    // Hours
    mondayOpen: null,
    mondayClose: null,
    tuesdayOpen: null,
    tuesdayClose: null,
    wednesdayOpen: null,
    wednesdayClose: null,
    thursdayOpen: null,
    thursdayClose: null,
    fridayOpen: null,
    fridayClose: null,
    saturdayOpen: null,
    saturdayClose: null,
    sundayOpen: null,
    sundayClose: null,
  });

  // Alert state
  const [alert, setAlert] = useState({
    show: false,
    type: "",
    message: "",
  });

  useEffect(() => {
    if (emplacement) {
      setFormData({
        // Services
        dinein: emplacement.dinein || false,
        takeout: emplacement.takeout || false,
        delivery: emplacement.delivery || false,
        meetingRoom: emplacement.meeting_room || false,
        terrace: emplacement.terrace || false,

        // Hours
        mondayOpen: emplacement.monday_open
          ? dayjs(emplacement.monday_open, "HH:mm:ss")
          : null,
        mondayClose: emplacement.monday_close
          ? dayjs(emplacement.monday_close, "HH:mm:ss")
          : null,
        tuesdayOpen: emplacement.tuesday_open
          ? dayjs(emplacement.tuesday_open, "HH:mm:ss")
          : null,
        tuesdayClose: emplacement.tuesday_close
          ? dayjs(emplacement.tuesday_close, "HH:mm:ss")
          : null,
        wednesdayOpen: emplacement.wednesday_open
          ? dayjs(emplacement.wednesday_open, "HH:mm:ss")
          : null,
        wednesdayClose: emplacement.wednesday_close
          ? dayjs(emplacement.wednesday_close, "HH:mm:ss")
          : null,
        thursdayOpen: emplacement.thursday_open
          ? dayjs(emplacement.thursday_open, "HH:mm:ss")
          : null,
        thursdayClose: emplacement.thursday_close
          ? dayjs(emplacement.thursday_close, "HH:mm:ss")
          : null,
        fridayOpen: emplacement.friday_open
          ? dayjs(emplacement.friday_open, "HH:mm:ss")
          : null,
        fridayClose: emplacement.friday_close
          ? dayjs(emplacement.friday_close, "HH:mm:ss")
          : null,
        saturdayOpen: emplacement.saturday_open
          ? dayjs(emplacement.saturday_open, "HH:mm:ss")
          : null,
        saturdayClose: emplacement.saturday_close
          ? dayjs(emplacement.saturday_close, "HH:mm:ss")
          : null,
        sundayOpen: emplacement.sunday_open
          ? dayjs(emplacement.sunday_open, "HH:mm:ss")
          : null,
        sundayClose: emplacement.sunday_close
          ? dayjs(emplacement.sunday_close, "HH:mm:ss")
          : null,
      });
    }
  }, [emplacement]);

  const handleCheckboxChange = (field) => (event) => {
    setFormData((prev) => ({
      ...prev,
      [field]: event.target.checked,
    }));
  };

  const handleTimeChange = (field) => (newValue) => {
    setFormData((prev) => ({
      ...prev,
      [field]: newValue,
    }));
  };

  const handleSave = async () => {
    const saveData = {
      noEmplacement: emplacement?.noEmplacement,
      ...formData,
      username: userState.username,
      tab: "Time",
    };

    const result = await onSave(saveData, userState.token);

    setAlert({
      show: true,
      type: result.success ? "success" : "error",
      message: result.message,
    });
  };

  return (
    <Paper elevation={3} sx={{ mx: "5%" }}>
      <Box sx={{ p: 5 }}>
        <Grid container spacing={3}>
          {/* Services Section */}
          <Grid item xs={12}>
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.dinein}
                    onChange={handleCheckboxChange("dinein")}
                  />
                }
                label={t("DineIn")}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.delivery}
                    onChange={handleCheckboxChange("delivery")}
                  />
                }
                label={t("Delivery")}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.takeout}
                    onChange={handleCheckboxChange("takeout")}
                  />
                }
                label={t("TakeOut")}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.meetingRoom}
                    onChange={handleCheckboxChange("meetingRoom")}
                  />
                }
                label={t("MeetingRoom")}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.terrace}
                    onChange={handleCheckboxChange("terrace")}
                  />
                }
                label={t("Terace")}
              />
            </Box>
          </Grid>

          {/* Hours Section */}
          {[
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
            "Sunday",
          ].map((day) => (
            <Grid item xs={12} sm={8} key={day}>
              <h4>{t(day)}</h4>
              <Box sx={{ display: "flex", gap: 2 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimeField
                    label={t("From")}
                    value={formData[`${day.toLowerCase()}Open`]}
                    onChange={handleTimeChange(`${day.toLowerCase()}Open`)}
                  />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimeField
                    label={t("To")}
                    value={formData[`${day.toLowerCase()}Close`]}
                    onChange={handleTimeChange(`${day.toLowerCase()}Close`)}
                  />
                </LocalizationProvider>
              </Box>
            </Grid>
          ))}

          <Grid item xs={12}>
            <Button variant="contained" onClick={handleSave} sx={{ mt: 2 }}>
              {t("Save")}
            </Button>
          </Grid>
        </Grid>
      </Box>

      <CustomAlert
        open={alert.show}
        closeMessage={() => setAlert((prev) => ({ ...prev, show: false }))}
        typeMessage={alert.type}
        message={alert.message}
      />
    </Paper>
  );
}

export default TabWebTime;
