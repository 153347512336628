import React, { useEffect, useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import PersonIcon from "@mui/icons-material/Person";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import {
  GridRowModes,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";
import Cookies from "js-cookie";
import CreateEmployee from "./CreateEmployee";
import CustomAlert from "../Common/Components/CustomAlert";
import { useSelector } from "react-redux";

import "../../css/UserManagement.css";

export function UserManagement() {
  const { t } = useTranslation(["Employee"], ["Common"]);
  const [restaurant, setRestaurent] = useState();
  const [restaurants, setRestaurents] = useState([]);
  const [employes, setEmployes] = useState([]);
  const [noEmplacement, setNoEmplacement] = useState([]);
  const [rows, setRows] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [departments, setDepartments] = useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [showAlert, setShowAlert] = useState(false);

  const [typeMessage, setTypeMessage] = useState("");
  const [message, setMessage] = useState("");

  const [datagridEditMode, setDatagridEditMode] = useState(false);

  const userState = useSelector((state) => state.user);
  const userRole = useSelector((state) => state.user.roles);

  const columns = [
    { field: "isInactive", headerName: "Inactive", width: 50 },
    {
      field: "lastName",
      headerName: t("Lastname"),
      width: 130,
      editable: true,
    },
    {
      field: "firstName",
      headerName: t("Firstname"),
      width: 130,
      editable: true,
    },
    { field: "username", headerName: t("Username"), width: 130 },
    { field: "email", headerName: t("Email"), width: 250, editable: true },
    { field: "nodooliz", headerName: "No dooli'z", width: 80, editable: true },
    {
      field: "langue",
      headerName: t("Language"),
      width: 80,
      editable: true,
      type: "singleSelect",
      valueOptions: ["FR", "EN"],
    },
    {
      field: "department",
      headerName: t("Department"),
      width: 130,
      editable: true,
      type: "singleSelect",
      valueOptions: departments,
    },
    {
      field: "lastLogin",
      headerName: t("LastLogin"),
      type: "Date",
      width: 130,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        const findRow = rows.find((row) => row.id === id);

        if (findRow.department === "Franchisee") {
          if (userRole.includes("InternalEmployee")) {
            if (isInEditMode) {
              return [
                <GridActionsCellItem
                  icon={<SaveIcon />}
                  label="Save"
                  sx={{
                    color: "primary.main",
                  }}
                  onClick={handleSaveClick(id)}
                />,
                <GridActionsCellItem
                  icon={<CancelIcon />}
                  label="Cancel"
                  className="textPrimary"
                  onClick={handleCancelClick(id)}
                  color="inherit"
                />,
              ];
            }
            if (findRow.isInactive === false) {
              return [
                <GridActionsCellItem
                  icon={<EditIcon />}
                  label="Edit"
                  className="textPrimary"
                  onClick={handleEditClick(id)}
                  color="inherit"
                />,
                <GridActionsCellItem
                  icon={<PersonOffIcon />}
                  label="Delete"
                  onClick={handleDeactivateClick(id)}
                  color="inherit"
                />,
              ];
            } else {
              return [
                <GridActionsCellItem
                  icon={<EditIcon />}
                  label="Edit"
                  className="textPrimary"
                  onClick={handleEditClick(id)}
                  color="inherit"
                />,
                <GridActionsCellItem
                  icon={<PersonIcon />}
                  label="Delete"
                  onClick={handleActivateClick(id)}
                  color="inherit"
                />,
              ];
            }
          } else {
            return [<div>{t("NotEditable")}</div>];
          }
        } else {
          if (isInEditMode) {
            return [
              <GridActionsCellItem
                icon={<SaveIcon />}
                label="Save"
                sx={{
                  color: "primary.main",
                }}
                onClick={handleSaveClick(id)}
              />,
              <GridActionsCellItem
                icon={<CancelIcon />}
                label="Cancel"
                className="textPrimary"
                onClick={handleCancelClick(id)}
                color="inherit"
              />,
            ];
          }
          if (findRow.isInactive === false) {
            return [
              <GridActionsCellItem
                icon={<EditIcon />}
                label="Edit"
                className="textPrimary"
                onClick={handleEditClick(id)}
                color="inherit"
              />,
              <GridActionsCellItem
                icon={<PersonOffIcon />}
                label="Delete"
                onClick={handleDeactivateClick(id)}
                color="inherit"
              />,
            ];
          } else {
            return [
              <GridActionsCellItem
                icon={<EditIcon />}
                label="Edit"
                className="textPrimary"
                onClick={handleEditClick(id)}
                color="inherit"
              />,
              <GridActionsCellItem
                icon={<PersonIcon />}
                label="Delete"
                onClick={handleActivateClick(id)}
                color="inherit"
              />,
            ];
          }
        }
      },
    },
  ];

  const GetExternalDepartment = async () => {
    fetch(
      `${process.env.REACT_APP_API_URL}/Localization/GetExternalDepartment`,
      {
        method: "GET",
        withCredentials: true,
        credentials: "include",

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userState.token}`,
        },
      }
    )
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        let departmentFR = [];
        let departmenetEN = [];
        data.forEach(
          (d) => d.textEn !== "Franchisee" && departmenetEN.push(d.textEn)
        );

        setDepartments(departmenetEN);
      });
  };

  const handleChangeRestaurent = (e) => {
    setRestaurent(e.target.value);
    setNoEmplacement(
      restaurants.filter((r) => r.nomEmplacement === e.target.value)[0]
        .noEmplacement
    );
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
      setDatagridEditMode(false);
      setRowModesModel({
        ...rowModesModel,
        [params.id]: { mode: GridRowModes.View, ignoreModifications: true },
      });
    }
  };

  const handleEditClick = (id, params, event) => () => {
    setDatagridEditMode(true);
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    processRowUpdate(rows.filter((row) => row.id === id)[0]);
  };

  const handleDeactivateClick = (id) => () => {
    rows.filter((row) => row.id === id)[0].isInactive = true;
    processRowUpdate(rows.filter((row) => row.id === id)[0]);
  };

  const handleActivateClick = (id) => () => {
    setDatagridEditMode(true);

    rows.filter((row) => row.id === id)[0].isInactive = false;
    processRowUpdate(rows.filter((row) => row.id === id)[0]);
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
    setDatagridEditMode(false);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: 800,
    width: 800,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const GetRestaurants = async () => {
    fetch(
      `${process.env.REACT_APP_API_URL}/Localization/GetRestaurantsByUser`,
      {
        method: "GET",
        withCredentials: true,
        credentials: "include",

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userState.token}`,
        },
      }
    )
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        setRestaurents(data);
        setRestaurent(data[0].nomEmplacement);
        setNoEmplacement(data[0].noEmplacement);
      });
  };

  const GetEmployesByRestaurants = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/employee/GetEmployesByRestaurants/${noEmplacement}`,
        {
          method: "GET",
          credentials: "include", // Ensure cookies are sent
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userState.token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Failed to fetch employees: ${response.statusText}`);
      }

      const data = await response.json();
      setEmployes(data);
    } catch (error) {
      console.error("Error fetching employees:", error.message);
    }
  };

  const Buildrows = () => {
    let rows = [];
    let i = 0;
    employes.forEach((e) => {
      if (!e.department.estInterne) {
        rows.push({
          id: i++,
          isInactive: e.estInactif,
          firstName: e.name,
          lastName: e.lastName,
          username: e.username,
          email: e.email,
          nodooliz: e.posPwd,
          langue: e.language,
          department: e.department.textEn,
          lastLogin: e.lastLogin,
          action: "",
        });
      }
    });
    setRows(rows);
  };

  useEffect(() => {
    Buildrows();
  }, [employes]);

  useEffect(() => {
    GetRestaurants();
    GetExternalDepartment();
  }, []);

  useEffect(() => {
    GetEmployesByRestaurants();
  }, [noEmplacement]);

  const handleOpen = async () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));

    /**********************/
    //SAVE ROW BD
    handleSaveEmploye(updatedRow);
    setDatagridEditMode(false);
    /**********************/
    return updatedRow;
  };

  const handleSaveEmploye = async (employe) => {
    console.log(employe);
    var employee = {
      id: employe.id,
      noEmplacement: 0,
      username: employe.username,
      name: employe.firstName,
      lastName: employe.lastName,
      email: employe.email,
      langue: employe.langue,
      noDooliz: employe.nodooliz,
      isInactive: employe.isInactive,
      department: {
        id: 0,
        textFr: employe.department,
        textEn: employe.department,
        estInactif: true,
        version: 0,
        estInterne: true,
        estExterne: true,
        idDepartementFoxPro: 0,
      },
    };

    fetch(`${process.env.REACT_APP_API_URL}/employee/SaveEmployeeRestaurant`, {
      method: "POST",
      withCredentials: true,
      credentials: "include",

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userState.token}`,
      },
      body: JSON.stringify(employee),
    })
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        if (data === "succes") {
          setTypeMessage("succes");
          setMessage("user saved");
          setShowAlert(true);
        }
        if (data === "echec") {
          setTypeMessage("echec");
          setMessage("There is an error, user saved");
          setShowAlert(true);
        }
      });
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    if (Object.values(newRowModesModel)[0]) {
      if (Object.values(newRowModesModel)[0].mode === "edit") {
        setDatagridEditMode(true);
      }
    }

    setRowModesModel(newRowModesModel);
  };

  return (
    <>
      <div>
        <Box sx={{ minWidth: 120 }}>
          <div className="UserManagement__Restaurant">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                {t("restaurant")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={restaurant ?? " "}
                label={t("restaurant")}
                onChange={(e) => handleChangeRestaurent(e)}
                disabled={datagridEditMode}
              >
                {restaurants.length > 0 &&
                  restaurants.map((resto) => (
                    <MenuItem
                      id={resto.noEmplacement}
                      value={resto.nomEmplacement}
                    >
                      {resto.nomEmplacement}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            {datagridEditMode ? (
              <div id="datagridInEddition">
                datagrid in eddition, save or cancel your modification to access
                another restaurant
              </div>
            ) : (
              ""
            )}
          </div>
          {restaurant && (
            <Button onClick={handleOpen}>{t("NewEmploye")}</Button>
          )}
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style} className="ArticleProperties__Modal">
              <CreateEmployee
                noEmplacement={noEmplacement}
                closeModal={handleClose}
              />
            </Box>
          </Modal>
        </Box>
        <div style={{ height: 600, width: "100%" }}>
          <DataGrid
            editMode="row"
            rows={rows}
            columns={columns}
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            isCellEditable={(params) => {
              // Allow edits only if user is InternalEmployee
              if (userRole.includes("InternalEmployee")) {
                return true;
              }
              // Additional department checks if needed
              return (
                params.row.department !== "Franchise" &&
                params.row.department !== "Franchisee"
              );
            }}
            slots={{
              toolbar: GridToolbar,
            }}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 100 },
              },
              filter: {
                filterModel: {
                  items: [
                    {
                      field: "isInactive",
                      operator: "contains",
                      value: "false",
                    },
                  ],
                },
              },
              columns: {
                columnVisibilityModel: {
                  isInactive: false,
                },
              },
            }}
            pageSizeOptions={[5, 10, 20, 50, 100]}
            sx={{ m: 2 }}
          />
        </div>
        <CustomAlert
          open={showAlert}
          closeMessage={setShowAlert}
          typeMessage={typeMessage}
          message={message}
        />
      </div>
    </>
  );
}

export default UserManagement;
