import React from "react";
import ComplainForm from "../../ComplainForm";
import { Modal, Box } from "@mui/material";

function AddComplaint({ open, onClose }) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: 800,
    width: 800,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style}>
        <ComplainForm />
      </Box>
    </Modal>
  );
}

export default AddComplaint;
