import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import CustomAlert from "../Common/Components/CustomAlert";

function TabEmplacement({ emplacement, onSave }) {
  const { t } = useTranslation(["Location"]);
  const userState = useSelector((state) => state.user);

  // Form state
  const [formData, setFormData] = useState({
    nameLocation: "",
    phoneNumber: "",
    email: "",
    address: "",
    city: "",
    zipCode: "",
    country: "",
    province: "",
    region: "",
  });

  // Location data state
  const [locationData, setLocationData] = useState({
    countries: [],
    provinces: [],
    regions: [],
  });

  // Alert state
  const [alert, setAlert] = useState({
    show: false,
    type: "",
    message: "",
  });

  useEffect(() => {
    if (emplacement) {
      setFormData({
        nameLocation: emplacement.nom || "",
        phoneNumber: emplacement.noTelPrincipal || "",
        email: emplacement.email || "",
        address: emplacement.adresseEmplacement?.adresse || "",
        city: emplacement.adresseEmplacement?.ville || "",
        zipCode: emplacement.adresseEmplacement?.codePostal || "",
        country: emplacement.adresseEmplacement?.pays || "",
        province: emplacement.adresseEmplacement?.province || "",
        region: emplacement.adresseEmplacement?.region || "",
      });
    }
  }, [emplacement]);

  const handleInputChange = (field) => (event) => {
    setFormData((prev) => ({
      ...prev,
      [field]: event.target.value,
    }));
  };

  const handleSave = async () => {
    const saveData = {
      noEmplacement: emplacement?.noEmplacement,
      ...formData,
      username: userState.username,
      tab: "Location",
    };

    const result = await onSave(saveData, userState.token);

    setAlert({
      show: true,
      type: result.success ? "success" : "error",
      message: result.message,
    });
  };

  return (
    <div>
      <Paper elevation={3} sx={{ marginRight: "5%", marginLeft: "5%" }}>
        <Box sx={{ padding: 5 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                id="Nom"
                label={t("Lastname")}
                name="Nom"
                fullWidth
                size="medium"
                autoComplete="off"
                variant="outlined"
                value={formData.nameLocation}
                onChange={handleInputChange("nameLocation")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="NoTelephonePrincipal"
                label={t("PhoneNumber")}
                name="No Telephone Principal"
                fullWidth
                size="medium"
                autoComplete="off"
                variant="outlined"
                value={formData.phoneNumber}
                onChange={handleInputChange("phoneNumber")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="Courriel"
                name="Courriel"
                label={t("Email")}
                fullWidth
                size="medium"
                autoComplete="off"
                variant="outlined"
                value={formData.email}
                onChange={handleInputChange("email")}
              />
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <Paper
        elevation={3}
        sx={{ marginRight: "5%", marginLeft: "5%", marginTop: "2%" }}
      >
        <Box sx={{ padding: 5 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                id="Adresse"
                label={t("Adress")}
                name="Adresse"
                fullWidth
                size="medium"
                autoComplete="off"
                variant="outlined"
                value={formData.address}
                onChange={handleInputChange("address")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="Ville"
                label={t("City")}
                name="Ville"
                fullWidth
                size="medium"
                autoComplete="off"
                variant="outlined"
                value={formData.city}
                onChange={handleInputChange("city")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel id="Pays">{t("Country")}</InputLabel>
                <Select
                  labelId="Pays"
                  id="Pays"
                  label={t("Country")}
                  value={formData.country}
                  onChange={handleInputChange("country")}
                >
                  {locationData.countries.map((p, i) => (
                    <MenuItem key={i} value={p}>
                      {p.nomFr}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel id="Province">{t("Province")}</InputLabel>
                <Select
                  labelId="Province"
                  id="Province"
                  label={t("Province")}
                  value={formData.province}
                  onChange={handleInputChange("province")}
                >
                  {locationData.provinces.map((p, i) => (
                    <MenuItem key={i} value={p}>
                      {p.nomFr}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel id="Region">{t("Region")}</InputLabel>
                <Select
                  labelId="Region"
                  id="Region"
                  label={t("Region")}
                  value={formData.region}
                  onChange={handleInputChange("region")}
                >
                  {locationData.regions.map((r, i) => (
                    <MenuItem key={i} value={r}>
                      {r.nomFr}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="codePostal"
                name="codePostal"
                label={t("Zipcode")}
                fullWidth
                size="medium"
                autoComplete="off"
                variant="outlined"
                value={formData.zipCode}
                onChange={handleInputChange("zipCode")}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Button variant="contained" onClick={handleSave}>
                {t("GeneralSave")}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <CustomAlert
        open={alert.show}
        closeMessage={() => setAlert((prev) => ({ ...prev, show: false }))}
        typeMessage={alert.type}
        message={alert.message}
      />
    </div>
  );
}

export default TabEmplacement;
