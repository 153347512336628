import React, { useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import "./css/RecipesCategory.css";

function RecipesCategory({
  name,
  image,
  onClick,
  selectedCategory,
  onDelete,
  onEdit,
  isAdmin,
}) {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (
      selectedCategory &&
      (selectedCategory.textEn === name || selectedCategory.textFr === name)
    ) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [selectedCategory, name]);

  const handleClick = (e) => {
    setIsActive(!isActive);
    onClick(e); // Pass the clicked recipe to the onClick handler
  };

  const handleDelete = (e) => {
    e.stopPropagation(); // Prevent triggering the category click
    onDelete(); // Call the onDelete handler
  };

  const handleEdit = (e) => {
    e.stopPropagation(); // Prevent triggering the category click
    onEdit(name); // Call the onEdit handler with the current category name
  };

  return (
    <div
      className={`RecipesCategory ${isActive ? "active" : ""}`}
      data={name}
      onClick={(e) => handleClick(e)}
    >
      <img src={`data:image/png;base64,${image}`} alt={name} />
      <div className="CategoryHeader">
        <h6>{name}</h6>
        {isAdmin && (
          <IconButton
            aria-label="edit"
            color="primary"
            size="small"
            onClick={handleEdit}
          >
            <EditIcon className="EditIcon" />
          </IconButton>
        )}
        {isAdmin && (
          <IconButton
            aria-label="delete"
            color="error"
            size="small"
            onClick={handleDelete}
          >
            <DeleteIcon className="DeleteIcon" />
          </IconButton>
        )}
      </div>
    </div>
  );
}

export default RecipesCategory;
