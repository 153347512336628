import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import { Grid, Button, Typography } from "@mui/material";
import AddComplaint from "./Modals/AddComplaint";
import ModifyComplaint from "./Modals/ModifyComplaint";
import { useSelector } from "react-redux";
import { FormatDateTimeAnneeMoisJour } from "../../../../utils/formateDate";
import CustomAlert from "../../../Common/Components/CustomAlert";

function ComplainAdmin() {
  const [complaints, setComplaints] = useState([]);
  const userState = useSelector((state) => state.user);
  const [openAddComplaint, setOpenAddComplaint] = useState(false);
  const [openModifyComplaint, setOpenModifyComplaint] = useState(false);
  const [selectedComplaint, setSelectedComplaint] = useState(null);

  const [alertOpen, setAlertOpen] = useState(false); // Control alert visibility
  const [alertMessage, setAlertMessage] = useState(""); // Set alert message
  const [alertType, setAlertType] = useState("success"); // Set alert type

  const allColumns = [
    { field: "date", headerName: "Date", width: 200 },
    { field: "id", headerName: "ID", width: 90 },
    { field: "restaurant", headerName: "Restaurant", width: 150 },
    { field: "phoneNumber", headerName: "Phone Number", width: 150 },
    { field: "personInCharge", headerName: "Person In Charge", width: 150 },
    { field: "product", headerName: "Product", width: 300 },
    { field: "problem", headerName: "Problem", width: 200 },
    { field: "quantity", headerName: "Quantity", width: 110 },
    { field: "unit", headerName: "Unit", width: 110 },
    {
      field: "supplier",
      headerName: "Supplier",
      width: 150,
      isAdminOnly: true,
    },
    {
      field: "transporteur",
      headerName: "Transporteur",
      width: 150,
      isAdminOnly: true,
    },
    { field: "status", headerName: "Status", width: 150 },
  ];

  const hasAdminAccess = (roles) =>
    ["CasseroleAdmin", "AdminTI"].some((role) => roles.includes(role));

  const columns = allColumns.filter(
    (col) => !col.isAdminOnly || hasAdminAccess(userState.roles)
  );

  useEffect(() => {
    const fetchComplaints = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/Casserole/GetComplaints`,
          {
            method: "GET",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userState.token}`,
            },
          }
        );
        if (!response.ok) throw new Error("Failed to fetch complaints");
        const data = await response.json();
        const formattedData = data.map((complaint) => ({
          id: complaint.id,
          date: FormatDateTimeAnneeMoisJour(complaint.dateCreation),
          restaurant: complaint.nomEmplacement || "",
          phoneNumber: complaint.noTel || "",
          personInCharge: complaint.nomResponsable || "",
          product:
            userState.lang === "EN"
              ? `${complaint.idItemAccPac} | ${complaint.nomEn} | ${complaint.format}`
              : `${complaint.idItemAccPac} | ${complaint.nomFr} | ${complaint.format}` ||
                "",
          problem: complaint.probleme || "",
          quantity: complaint.quantite || "",
          supplier: complaint.fournisseur || "",
          transporteur: complaint.transporteur || "",
          unit: complaint.unit,
          status:
            userState.lang === "EN"
              ? complaint.statutEn
              : complaint.statutFr || "",
        }));
        setComplaints(formattedData);
      } catch (error) {
        console.error("Error fetching complaints:", error);
        alert("Unable to fetch complaints. Please try again later.");
      }
    };
    fetchComplaints();
  }, [userState.token]);

  const handleRowClick = (params) => {
    setSelectedComplaint(params.row);
  };

  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent="center"
        spacing={2}
        sx={{ ml: 2, minHeight: "80vh" }}
      >
        {hasAdminAccess(userState.roles) && (
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpenAddComplaint(true)}
            >
              Add Complaint
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={!selectedComplaint}
              onClick={() => setOpenModifyComplaint(true)}
              style={{ marginLeft: "10px" }}
            >
              Modify Complaint
            </Button>
          </Grid>
        )}
        <Grid item>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "80vh", // Adjust to your needs
              width: "90%", // Adjust to your needs
            }}
          >
            {complaints.length > 0 ? (
              <DataGridPro
                rows={complaints}
                columns={columns}
                initialState={{
                  filter: {
                    filterModel: {
                      items: [
                        {
                          field: "status", // Ensure the field name is correct and matches exactly
                          operatorValue: "equals",
                          value: "Received", // Default filter value
                        },
                      ],
                    },
                  },
                }}
                slots={{
                  toolbar: GridToolbar,
                }}
                pageSizeOptions={[5, 10, 50]}
                onRowClick={handleRowClick}
              />
            ) : (
              <Typography variant="body1" align="center">
                No complaints available.
              </Typography>
            )}
          </Box>
        </Grid>
        {openAddComplaint && (
          <AddComplaint
            open={openAddComplaint}
            onClose={() => setOpenAddComplaint(false)}
          />
        )}
        {openModifyComplaint && (
          <ModifyComplaint
            open={openModifyComplaint}
            onClose={() => setOpenModifyComplaint(false)}
            complaintData={selectedComplaint}
            setAlertOpen={setAlertOpen} // Pass alert control functions
            setAlertMessage={setAlertMessage}
            setAlertType={setAlertType}
          />
        )}
      </Grid>
      {/* Custom Alert for Feedback */}
      <CustomAlert
        open={alertOpen}
        message={alertMessage}
        typeMessage={alertType}
        closeMessage={setAlertOpen}
      />
    </>
  );
}

export default ComplainAdmin;
